.tone-image img {
    width: auto;
    height: 300px;
    max-width: 500px;
    margin-bottom: 16px;
    display: block;
}

.checkbox-holder {
    margin-bottom: 30px;
}
